export class Lancamento {
  id: number;
  tipoLancamentoEntrada: any;
  entrada: Date;
  saida: Date;
  entradaString: string;
  saidaString: string;
  timezone: string;
  observacaoFuncionario: string;
  observacaoSupervisor1: string;
  observacaoSupervisor2: string;
  feriado: boolean;
  foto: any;
  latitude: any;
  longitude: any;
  idChamado: number;

  constructor(bean: any) {
    //this.uuid = bean == null ? null : bean.uuid;
    this.id = null;
    this.feriado = false;
    this.tipoLancamentoEntrada = { id: 0, nome: '' };
  }
}
